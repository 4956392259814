<template>
  <div class="wrapper" v-if="!showFilials">

    <div class="row">

      <div class="col-12">
        <h3>Список филиалов</h3>
      </div>

      <div class="col-12">
        <p>Филиалы отсутствуют или ещё не внесены в систему.</p>
      </div>
      <router-link to="/filials/addfilials" class="btn btn-bg">Добавить филиал</router-link>
    </div>

  </div>







  <div class="wrapper list_wrapper" v-else>

    <div class="row space-between" v-if="filialsList.length">
      <div class="col-sm-4">
        <h3>Список филиалов</h3>
      </div>
      <div class="col-sm-8 text-left text-right-sm">
          <router-link to="/filials/addfilials" class="add">+ Добавить филиал</router-link>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-12">
        <p>Филиалы отсутствуют или ещё не внесены в систему</p>
      </div>
      <router-link to="/filials/addfilials" class="btn btn-bg">Добавить филиал</router-link>
    </div>

    <VuetifyDataTable
        :columns="tableData.columns"
        :searchQuery="''"
        :page="page"
        :addUrl="'filials/addfilials/'"
        :itemsPerPage="itemsPerPage"
        :items="filialsList"
        :pageCount="pageCount"
        @change="changePage($event)"
        @changePage="changePageNum($event)"
        @changeItems="changeItems($event)" />

  </div>
</template>

<script>


import VuetifyDataTable from "@/components/VuetifyDataTable";
export default {
  name: "Filials",
  components: {VuetifyDataTable},
  data() {
    return {
      showFilials: true,
      searchQuery: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      tableData: {
        columns: [
          {
            text: "Название",
            sortable: false,
            value: "filialName"
          },
          {
            text: "Адрес",
            sortable: false,
            value: "filialAddress"
          },
          {
            text: "КПП",
            sortable: false,
            value: "filialKpp"
          },
          {
            text: "",
            sortable: false,
            value: "filialId"
          },
        ],

        displayNames: {
          filialName: "Название",
          filialAddress: "Адрес",
          filialKpp: "КПП",
          id: "",
          filialId: "",
        },
        columnsToDisplay: ['filialName', 'filialAddress', 'filialKpp', 'filialId']
      }
    }
  },
  computed: {
    filialsList() {
      return this.$store.getters.filialsList;
    },
  },
  mounted() {
    this.$store.dispatch('getFilials');
  },
  methods: {
    changeItems(evt) {
      this.itemsPerPage = parseInt(evt.target.value);
    },
    changePageNum(evt) {
      this.page = evt;
    },
    changePage(evt) {
      this.pageCount = evt;
    }
  }
}
</script>

<style scoped lang="scss">

h3 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 32px;
}

p {
  margin-bottom: 56px;
}


.btn {
  width: 100%;
  max-width: 350px;
}

.list_wrapper {
  font-size: 18px;
  line-height: normal;

  h3 {
    margin-bottom: 18px;
  }


  .search {
    position: relative;

    .row {
      padding-right: 65px !important;
    }
    input {
      width: 100%;
      color: $text-gray;
      border-radius: 8px;
      border: solid 1px #e6e6e6;
      padding: 17px;
      padding-left: 20px;
      font-size: 18px;
      vertical-align: middle;
    }

    input:focus {
      outline: none;
      border-color: $navy;
      color: $navy;
    }

    .btn {
      position: absolute;
      width: auto;
      height: 100%;
      right: 0px;
      padding: 18px;
    }
  }

}



@include md-max {
  h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
  }

  .text-left {
    margin-top: 15px;
  }


  .list_wrapper h3 {
    margin-bottom: 0px;
  }

  p {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 40px;
  }

  .btn {
    width: 100%;
    max-width: 100%;
  }

  .list_wrapper .search {
    .row {
      padding-right: 65px !important;
      margin-top: 15px;
    }
    input {
      padding: 13px;
      padding-left: 15px;
      font-size: 16px;
    }


    .btn {
      padding: 15px;
      height: auto;
    }
  }
}

</style>
